import * as React from "react";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight } from "react-feather";
import { CTALink, PageBlockNextPageDivider, primaryColour } from "../../styles/component-stylings";

const PAGE_TITLE = "Our values";

const ValuesPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>The AFP's core values are:</p>
                <ul>
                    <li>
                        <strong>Integrity</strong> – displayed through soundness of moral principle, honesty and sincerity.
                    </li>
                    <li>
                        <strong>Commitment</strong> – characterised by dedication, application, perseverance and a belief in our ability to achieve and add
                        value.
                    </li>
                    <li>
                        <strong>Excellence</strong> – never-ending search for improvement leads to excellence. We aim for excellence in everything we do.
                    </li>
                    <li>
                        <strong>Accountability</strong> – ownership of work or results and being answerable for outcomes.
                    </li>
                    <li>
                        <strong>Fairness</strong> – being impartial and equitable.
                    </li>
                    <li>
                        <strong>Trust</strong> – having faith and confidence and being able to rely and depend on others.
                    </li>
                    <li>
                        <strong>Respect</strong> – treating ourselves and all others with consideration.
                    </li>
                </ul>

                <p>In addition to the AFP's core values, DSC displays:</p>
                <ul>
                    <li>
                        <strong>Attention to detail</strong> - there are no unseen surfaces. Every aspect of what we do is to be of high quality, even parts
                        that no one will ever see.
                    </li>
                    <li>
                        <strong>Empathy</strong> - we provide solutions and support for expectional people in extraordinary circumstances. Our work is deeply
                        connected with not only operational requirements, but of those who are involved.
                    </li>
                    <li>
                        <strong>Focus</strong> - our solutions simplify complex tasks. We recognise and eliminate unimportant opportunities to achieve this.
                    </li>
                    <li>
                        <strong>Intuition</strong> - our work does not distract, interfere or hinder operations.
                    </li>
                    <li>
                        <strong>Impact</strong> - our achievements are not only effective but impactful.
                    </li>
                    <li>
                        <strong>Consistency</strong> - our successes are consistent because we are passionate in our work and are constantly seeking
                        self-improvement.
                    </li>
                </ul>

                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/who-we-are/partners' rel='next'>
                        <span>Partners</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default ValuesPage;
